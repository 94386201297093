import React from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  margin: 6rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 4rem 0;
  }

  button {
    background-color: ${props => props.theme.headerBg};
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    ${props => props.theme.fluidType(-0.5)};
  }
`

export default function JournalFilter({nodes, clickHandler}) {

    let sectionArray = []
    nodes.forEach(item => {
        sectionArray.push(
            item.node.section
        )
    })
    const mergedArray = [].concat.apply([], sectionArray)
    const uniqueTag = [...new Set(mergedArray.map(section => (section)))]

    return (
        <Holder>
            <p>Filters:</p>
            <button className="button fancy" onClick={() => clickHandler('')}>All</button>
            {uniqueTag.map((section) => {
                return (section &&
                    <button key={section} onClick={() => clickHandler(section)}
                            className="button fancy">{section}</button>)
            })}
        </Holder>
    )
}

JournalFilter.propTypes = {
    tags: PropTypes.object
};