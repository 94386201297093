import React, {useState} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/molecules/SEO';
import PageHolder from '../components/organisms/PageHolder';
import Container from '../components/atoms/Container';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import Card from "../components/molecules/Card";
import JournalFilter from "../components/molecules/JournalFilter";


const Holder = styled.div`
  @media ( ${props => props.theme.breakpoints.lg} ) {
    column-count: 3;
    column-gap: 1rem;
    > div {
      padding-bottom: 1rem;
    }
  }
`;

const HeroText = styled.div`
  margin: 0 0 8rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 1rem;
    margin: 4rem 0;
  }

  h1 {
    ${props => props.theme.fluidType(1.2)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(0.8)};
    }
  }
`;

function JournalListPage({data}) {
    const [filter, setFilter] = useState('')
    const filtered = data.allContentfulJournal.edges.filter((post) => post.node.section?.includes(filter))

    return (
        <PageHolder bg="cream">
            <SEO title="Journal"/>
            <Container>
                <HeroText>
                    <h1>
                        <MarkdownConverter content={data.contentfulGlobalSettings.newsPageHeading.newsPageHeading}/>
                    </h1>
                </HeroText>
                <JournalFilter clickHandler={setFilter} nodes={data.allContentfulJournal.edges}/>
            </Container>
            <Container>
                <Holder>
                    {(filter ? filtered : data.allContentfulJournal.edges).map(item => (
                        <Card key={item.node.id} post={item.node}/>
                    ))}
                </Holder>
            </Container>
        </PageHolder>
    )
}

export default JournalListPage;

export const journalListPageQuery = graphql`
    query newsListPageQuery($skip: Int!, $limit: Int!) {
        allContentfulJournal(
            sort: {fields: [createdAt], order: DESC}
            limit: $limit
            skip: $skip
        ) {
        edges {
            node {
                id
                slug
                title
                section
                sys {
                    contentType {
                        sys {
                            id
                        }
                    }
                }
                featuredImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
            }
          }  
        }
        contentfulGlobalSettings(title: {eq: "Global Settings"}) {
          newsPageHeading {
            newsPageHeading
          }
        }
    }

`;
