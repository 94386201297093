import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

const Holder = styled.div`
  break-inside: avoid-column;
  page-break-inside: avoid;
  display: table;
  width: 100%;
  margin-bottom: 2rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 0;
  }
`;

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr 4rem;
  grid-gap: 1rem;
  align-items: start;
  margin: 0.3rem 0 0 0;

  > :nth-child(2) {
    justify-self: end;
    text-align: right;
  }

  .h6 {
    color: ${props => props.theme.colours[props.colour]};
    font-family: "Eliza", Helvetica, Arial, sans-serif;
    ${props => props.theme.fluidType(-1)};
    margin: 0;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-0.5)};
    }

    svg {
      .path {
        fill: ${props => props.theme.colours[props.colour]} !important;
      }
    }
`;

function Card({post, arrow, indent, colour, aspectRatio}) {
    const slug = `/${post.sys.contentType.sys.id}/${post.slug}`;
    return (
        <Holder>
            <Link to={slug}>
                <GatsbyImage
                    style={{aspectRatio: aspectRatio}}
                    alt={post.title}
                    image={post.featuredImage.gatsbyImageData}/>
                <Text indent={indent} arrow={arrow}>
                    <h2 colour={colour} className="h6">{post.section && `${post.section} • `}{post.title}</h2>
                </Text>
            </Link>
        </Holder>
    )
}

Card.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        sys: PropTypes.object.isRequired,
        featuredImage: PropTypes.object.isRequired,
    }),
    arrow: PropTypes.bool,
    indent: PropTypes.bool,
    colour: PropTypes.oneOf(['black', 'cream']),
};

Card.defaultProps = {
    arrow: false,
    indent: false,
    colour: 'cream',
};

export default Card;
